<template>
  <v-card id="promotion-list">
    <!-- search -->
    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <div class="d-flex align-center pb-5">

        <!-- create Promotion -->
        <v-btn
          color="primary"
          class="me-3"
          :disabled="hasNotPermission('Permissions.ApplicationInfoCreate')"
          @click="createDialogShow()"
        >
          <v-icon
            size="18"
            class="me-1"
          >
            {{ icons.mdiPlus }}
          </v-icon>
          <span>Добавить</span>
        </v-btn>
      </div>
      <v-spacer></v-spacer>
    </v-card-text>

    <!-- table -->
    <v-data-table
      :headers="headers"
      :items="applicationDescriptions"
      :server-items-length="totalCount"
      :loading="loading"
      :page.sync="filters.pageNumber"
      :items-per-page.sync="filters.pageSize"
      :sort-by.sync="filters.sortBy"
      :sort-desc.sync="filters.sortDesc"
      class="text-no-wrap"
    >

      <!-- name -->
      <template #[`item.name`]="{item}">
        {{ item.name }}
      </template>

      <template #[`item.type`]="{item}">
        {{ item.type }}
      </template>

      <template #[`item.publicationDate`]="{item}">
        <span class="text-no-wrap">{{ toDate(item.publicationDate) }}</span>
      </template>

      <template #[`item.isActive`]="{item}">
        {{ getBooleanString(item.isActive) }}
      </template>

      <!-- actions -->
      <template #[`item.actions`]="{item}">
        <div class="d-flex align-center justify-center">

          <!-- edit -->
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                small
                :disabled="hasNotPermission('Permissions.ApplicationInfoUpdate')"
                v-bind="attrs"
                v-on="on"
                @click="updateDialogShow(item.id)"
              >
                <v-icon size="18">
                  {{ icons.mdiPencilOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>Редактирование</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                small
                :disabled="item.isActive || hasNotPermission('Permissions.ApplicationInfoCreate')"
                @click="activate(item.id)"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon size="18">
                  {{ icons.mdiCheck }}
                </v-icon>
              </v-btn>
            </template>
            <span>Активировать</span>
          </v-tooltip>

          <!-- delete -->
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                small
                :disabled="hasNotPermission('Permissions.ApplicationInfoDelete')"
                v-bind="attrs"
                v-on="on"
                @click="deletePopupShow(item.id, item.name)"
              >
                <v-icon size="18">
                  {{ icons.mdiDeleteOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>Удалить</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
    <delete-dialog @delete-confirmed="deleteApplicationDescription" :label="deleteLabel" ref="deletePopup" />
    <application-description-form :label="'Редактирование информации о приложении'" :cancellation="updateDialogCancel" :show="updateDialog"
                                  :save="updateApplicationDescription" :description-type="type"></application-description-form>
    <application-description-form :label="'Создание информации о приложении'" :cancellation="createDialogCancel" :show="createDialog"
                                  :save="addApplicationDescription" :description-type="type"></application-description-form>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { mapFormHandlers } from '@/helpers/forms'
import DeleteDialog from '@/components/delete-dialog'
import ApplicationDescriptionForm from './application-description-form'
import {
  mdiTrendingUp,
  mdiPlus,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiEyeOutline,
  mdiPencilOutline,
  mdiCheck
} from '@mdi/js'
import { toDateSting, hasPermission } from '@/helpers/index'

export default {
  components: { DeleteDialog, ApplicationDescriptionForm },
  props: ['type'],
  name: 'ApplicationDescriptionList',
  data: () => ({
    createDialog: false,
    updateDialog: false,
    icons: {
      mdiTrendingUp,
      mdiPlus,
      mdiPencilOutline,
      mdiDeleteOutline,
      mdiDotsVertical,
      mdiEyeOutline,
      mdiCheck
    },
    deleteLabel: '',
    deleteMethod: null,
  }),
  computed: {
    ...mapFormHandlers('applicationDescription', [
      'filters.pageNumber',
      'filters.pageSize',
      'filters.searchQuery',
      'filters.sortBy',
      'filters.sortDesc',
    ]),
    ...mapState('applicationDescription', {
      applicationDescriptions: 'list',
      totalCount: 'totalCount',
      loading: 'loading',
      searchQuery: 'searchQuery',
    }),
    headers() {
      return [
        { text: 'Наименование', value: 'name' },
        { text: 'Тип', value: 'type', sortable: false },
        { text: 'Дата публикации', value: 'publicationDate' },
        { text: 'Активное', value: 'isActive' },
        {
          text: 'Действия',
          value: 'actions',
          align: 'center',
          sortable: false,
        },
      ]
    },
  },
  async created() {
    document.title = 'О приложении'
    this.fetchApplicationDescriptions()
  },
  mounted() {
  },
  methods: {
    ...mapActions('applicationDescription', {
      fetchApplicationDescriptions: 'fetchList',
      deleteApplicationDescription: 'delete',
      activate: 'activate'
    }),
    hasNotPermission(val){
      return !hasPermission(val);
    },
    ...mapActions('applicationDescription/form', ['create', 'resetForm', 'fetchById']),
    deletePopupShow(id, name) {
      this.deleteLabel = 'Описание ' + name + ' будет удалено.'
      this.$refs.deletePopup.open(id)
    },
    async addApplicationDescription() {
      await this.create()
      this.createDialog = false
    },
    async updateApplicationDescription() {
      await this.$store.dispatch('applicationDescription/form/update')
      this.updateDialog = false
    },
    getBooleanString(val){
      return val === true ? 'Да' : 'Нет'
    },
    toDate(val){
      return toDateSting(val);
    },
    createDialogShow() {
      this.createDialog = true
    },
    createDialogCancel(){
      this.createDialog = false
    },
    updateDialogCancel() {
      this.updateDialog = false
    },
    updateDialogShow(id) {
      this.fetchById(id)
      this.updateDialog = true
    },
  },
}
</script>

<style lang="scss" scoped>
#promotion-list {
  .promotion-list-search {
    max-width: 10.625rem;
  }
}
</style>
