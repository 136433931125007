<template>
  <v-card>
    <v-tabs
      v-model="tab"
      show-arrows
      @change="changed"
    >
      <v-tab v-for="(item, index) in applicationDescriptionTypesDropdown" :key="index">
        <span>{{ item.text }}</span>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item v-for="(item, index) in applicationDescriptionTypesDropdown" :key="index">
        <application-description-list :type="item.value"></application-description-list>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>

import { mapActions, mapGetters, mapState } from 'vuex'
import ApplicationDescriptionList from './application-description-list'
import { mapFormHandlers } from '@/helpers/forms'

export default {
  name: 'ApplicationInfoTab',
  components: { ApplicationDescriptionList },
  data: () => ({
    tab: null,
  }),
  computed: {
    ...mapGetters('dictionary', { applicationDescriptionTypesDropdown: 'applicationDescriptionTypesDropdown' }),
    ...mapFormHandlers('applicationDescription', ['filters.type']),
  },
  methods: {
    ...mapActions('dictionary', ['fetchApplicationDescriptionTypes']),
    changed(val) {
      this.filters.type = this.applicationDescriptionTypesDropdown[val].value
    },
  },
  async created() {
    await this.fetchApplicationDescriptionTypes()
  },
}
</script>
