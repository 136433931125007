<template>
  <v-dialog
    v-model='show'
    max-width='40vw'
    @click:outside='cancel'
  >
    <v-card
      flat
      class='pa-3 mt-2'
    >
      <v-card-title class='justify-center text-h5'>
        {{ label }}
      </v-card-title>
      <v-card-text>
        <v-form class='multi-col-validation mt-6' ref='form'>
          <v-row>
            <v-col
              cols='12'
              md='6'
            >
              <date-picker
                v-model='publicationDate'
                label='Дата публикации'
                :rules='[rules.required]'
              ></date-picker>
            </v-col>

            <v-col
              cols='12'
              md='6'
            >
              <v-select
                v-model='applicationTypes'
                :items='applicationTypesDropdown'
                :menu-props="{ maxHeight: '400' }"
                label='Типы приложений'
                multiple
                dense
                outlined
                persistent-hint
                hide-details
              ></v-select>
            </v-col>

            <v-col
              cols='12'
              md='6'
            >
              <v-select
                v-model='type'
                :items='applicationDescriptionTypesDropdown'
                :menu-props="{ maxHeight: '400' }"
                label='Тип'
                dense
                outlined
                persistent-hint
                hide-details
              ></v-select>
            </v-col>

            <v-spacer>
            </v-spacer>

            <v-col
              md="12"
              cols="24"
            >
              <v-tabs
                v-model="tab"
                show-arrows
              >
                <v-tab v-for="(item, index) in translations" :key="index">
                  <span>{{ item.language }}</span>
                </v-tab>
              </v-tabs>
              <v-spacer></v-spacer>
              <v-tabs-items v-model="tab">
                <v-tab-item v-for="(item, index) in translations" :key="index">
                  <v-col
                    cols="24"
                    md="12"
                  >
                    <v-text-field
                      v-model="item.name"
                      label="Наименование"
                      dense
                      outlined
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="24"
                    md="12"
                  >
                    Описание
                    <tip-tap
                      :rules="[rules.required]"
                      v-model="item.description"
                    ></tip-tap>
                  </v-col>
                </v-tab-item>
              </v-tabs-items>
            </v-col>

            <v-col cols='12'>
              <v-btn
                @click='saveData'
                color='primary'
                class='me-3 mt-4'
              >
                Сохранить
              </v-btn>
              <v-btn
                color='secondary'
                outlined
                class='mt-4'
                type='reset'
                @click='cancel'
              >
                Отмена
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiCheck, mdiClose } from '@mdi/js'
import { mapFormHandlers } from '@/helpers/forms'
import { mapActions, mapGetters } from 'vuex'
import DatePicker from '@/components/date-picker'
import TipTap from '@/components/tip-tap'
import { rules } from '@/helpers/validation'

export default {
  name: 'ApplicationDescriptionForm',
  props: ['save', 'show', 'cancellation', 'label', 'descriptionType'],
  components: { TipTap, DatePicker },
  data: () => ({
    tab: null,
    icons: {
      mdiCheck,
      mdiClose,
    },
    rules,
  }),
  watch: {
    show: function(newValue) {
      if (newValue) {
        this.setType()
        if (this.$refs.form) {
          this.$refs.form.resetValidation()
        }
      }
    },
  },
  computed: {
    ...mapFormHandlers('applicationDescription/form', [
      'translations',
      'publicationDate',
      'type',
      'applicationTypes',
    ]),
    ...mapGetters('dictionary', { applicationTypesDropdown: 'applicationTypesDropdown' }),
    ...mapGetters('dictionary', { applicationDescriptionTypesDropdown: 'applicationDescriptionTypesDropdown' }),
  },
  async mounted() {
    await this.fetchApplicationTypes()
    await this.fetchApplicationDescriptionTypes()
  },
  methods: {
    cancel() {
      this.resetForm()
      this.cancellation()
    },
    setType() {
      this.type = this.descriptionType
    },
    ...mapActions('applicationDescription/form', ['resetForm']),
    ...mapActions('dictionary', {
      fetchApplicationTypes: 'fetchApplicationTypes',
      fetchApplicationDescriptionTypes: 'fetchApplicationDescriptionTypes',
    }),
    async saveData() {
      if (this.$refs.form.validate()) {
        await this.save()
        this.resetForm()
      }
    },
  },
}
</script>
